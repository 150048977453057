<script setup>
import {defineProps, ref} from "vue";

const props = defineProps({
  allroles: Array | Object
})
const selectedRole = ref({});
const errors = ref({});
const phone_number = ref('');
const users = ref('');
const searching = ref(false);
const searchEnabled = ref(false);

function setRole(role) {
  selectedRole.value = role
}

function searchForUsers() {
  searching.value = true;
  searchEnabled.value = true;
  axios.get('/apis/v4/users', {
    params: {
      search: phone_number.value
    },

  }).then(resp => {
    searching.value = false;
    users.value = resp.data;
  }).catch(err => {
    searching.value = false;
  });
}

function addUser(user) {
  axios.post('/apis/v4/rbac', {
    'user_id': user.id,
    'role_id': selectedRole.value.id
  }).then(resp => {
    location.reload();
  });
}
</script>

<template>
  <div>

    <div class="table-responsive">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th>#</th>
          <th>Role</th>
          <th>Number of Users</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(role,index) in allroles">
          <td scope="row">{{ index + 1 }}</td>
          <td>{{ role.name }}</td>
          <td>{{ role.users_count }}</td>
          <td>
            <a class="btn btn-outline-dark btn-sm" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button"
               aria-controls="offcanvasExample"
               @click="setRole(role)"
            >
              Add User
              <i class="fa fa-plus-circle"></i>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample"
         aria-labelledby="offcanvasExampleLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasExampleLabel">Add user to
          <strong>{{ selectedRole?.name }}</strong>
        </h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <hr>
        <form action="">
          <div class="input-group mb-3">
            <input type="text" class="form-control" id="phone_number" v-model="phone_number"
                   placeholder="Search phone number"
                   aria-describedby="phone_number-help"
                   :class="[errors.phone_number ? 'is-invalid': '',!errors.phone_number && Object.keys(errors).length > 1 ? 'is-valid': '']"
            >
            <button
                @click="searchForUsers"
                :disabled="searching"
                class="btn btn-outline-secondary" type="button" id="button-addon2">
              Search
              <i class="fa fa-search" v-if="!searching"></i>
              <i class="fa fa-spin fa-spinner" v-else></i>
            </button>
          </div>

          <div class="" v-if="Object.keys(users).length > 0">

            <div class="table-responsive">
              <table class="table table-striped table-bordered">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(user,index) in users">
                  <td scope="row">{{ index + 1 }}</td>
                  <td>{{ user.name }}</td>
                  <td>
                    <button
                        @click.prevent="addUser(user)"
                        class="btn btn-outline-dark btn-sm">
                      Grant Access
                      <i class="fa fa-plus-circle text-green"></i>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>